
































































import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import EmptyListNotification from '../../../../Component/Misc/EmptyListNotification/EmptyListNotification.vue';
import InfinityLoadingSpinner from '../../../../Component/Misc/Spinner/InfinityLoadingSpinner.vue';
import Tabs from '../../../../Component/Misc/Tabs/Tabs.vue';
import NewsAndEventItem from '../../../../Component/NewsAndEvent/Item/NewsAndEventItem.vue';

export default {
    name: 'NewsList',
    components: {NewsAndEventItem, Tabs, InfinityLoadingSpinner, EmptyListNotification},
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            openTabIndex: 0,
            page: 0,
            infiniteId: 1,

            newsItems: [],
            newsPagination: this.context.data.get('newsPagination', []),

            eventItems: [],
            eventPagination: this.context.data.get('eventPagination', [])
        };
    },
    computed: {
        currentEvents()
        {
            return this.eventItems.filter((item) => {
                return new Date(item.date) >= new Date();
            }).reverse();
        }
    },
    methods: {
        async onTabChange(index: number)
        {
            this.page = 0;
            this.newsItems = [];
            this.eventItems = [];
            this.openTabIndex = index;
            this.infiniteId++;
        },
        onInfinite(state)
        {
            if (this.openTabIndex === 0) {
                // News
                if (this.page >= this.newsPagination.totalPages) {
                    state.loaded();
                    state.complete();
                } else {
                    this.page++;
                    this.loadNews().then((result) => {
                        state.loaded();
                        this.newsItems = [...this.newsItems, ...result];
                    });
                }
            } else {
                // Events
                if (this.page >= this.eventPagination.totalPages) {
                    state.loaded();
                    state.complete();
                } else {
                    this.page++;
                    this.loadEvents().then((result) => {
                        state.loaded();
                        this.eventItems = [...this.eventItems, ...result];
                    });
                }
            }
        },
        loadNews()
        {
            return this.context.resourceApi.getCollection('news', {
                           'page': {
                               number: this.page,
                               size: this.newsPagination.perPage
                           }
                       })
                       .then(result => {
                           return result.response.data;
                       });
        },
        loadEvents()
        {
            return this.context.resourceApi.getCollection('event', {
                           'page': {
                               number: this.page,
                               size: this.eventPagination.perPage
                           },
                           sort: ['-date']
                       })
                       .then(async result => {
                           return result.response.data;
                       });
        }
    }
};
