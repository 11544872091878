



































import {formatDateAndTime} from '@labor-digital/helferlein/lib/FormatAndConvert/formatDateAndTime';
import LinkWrap from '../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import {Event} from '../../../Interface/Event';
import {News} from '../../../Interface/News';

export default {
    name: 'NewsAndEventItem',
    components: {LinkWrap},
    props: {
        item: null as News | Event
    },
    methods: {
        isNews(): boolean
        {
            return typeof this.item.dateEnd === 'undefined';
        },
        isEvent(): boolean
        {
            return !this.isNews();
        },
        formatDate(date: string, format: string)
        {
            return formatDateAndTime(format, new Date(date));
        }
    }
};
