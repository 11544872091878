var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "newsAndEvent",
      class: {
        "newsAndEvent--event": _vm.isEvent(),
        "newsAndEvent--news": _vm.isNews(),
      },
    },
    [
      _c(
        "link-wrap",
        { staticClass: "newsAndEvent__item", attrs: { link: _vm.item.link } },
        [
          _vm._v("\n        " + _vm._s(_vm.item.headline) + "\n\n        "),
          _vm.isEvent() && _vm.item.date
            ? _c("div", { staticClass: "newsAndEvent__item--date" }, [
                _c("span", { staticClass: "month" }, [
                  _vm._v(_vm._s(_vm.formatDate(_vm.item.date, "M"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "day" }, [
                  _vm._v(_vm._s(_vm.formatDate(_vm.item.date, "d"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "year" }, [
                  _vm._v(_vm._s(_vm.formatDate(_vm.item.date, "Y"))),
                ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }