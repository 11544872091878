var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newsAndEventList" },
    [
      _c(
        "tabs",
        {
          attrs: {
            items: [
              _vm.$t("newsAndEvent.list.news.headline"),
              _vm.$t("newsAndEvent.list.events.headline"),
            ],
          },
          on: { open: _vm.onTabChange },
        },
        [
          _c("template", { slot: _vm.$t("newsAndEvent.list.news.headline") }, [
            _c(
              "ul",
              { staticClass: "newsAndEventList__list" },
              _vm._l(_vm.newsItems, function (news) {
                return _c(
                  "li",
                  { staticClass: "newsAndEventList__item" },
                  [_c("news-and-event-item", { attrs: { item: news } })],
                  1
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "template",
            { slot: _vm.$t("newsAndEvent.list.events.headline") },
            [
              _vm.currentEvents.length > 0
                ? _c("span", { staticClass: "newsAndEventList__headline" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("newsAndEvent.list.events.up")) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "newsAndEventList__list" },
                _vm._l(_vm.currentEvents, function (event) {
                  return _c(
                    "li",
                    { staticClass: "newsAndEventList__item" },
                    [_c("news-and-event-item", { attrs: { item: event } })],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.currentEvents.length > 0
                ? _c("span", { staticClass: "newsAndEventList__headline" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("newsAndEvent.list.events.old")) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "newsAndEventList__list" },
                _vm._l(_vm.eventItems, function (event) {
                  return _c(
                    "li",
                    { staticClass: "newsAndEventList__item" },
                    [_c("news-and-event-item", { attrs: { item: event } })],
                    1
                  )
                }),
                0
              ),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("infinite-loading", {
        attrs: { identifier: _vm.infiniteId },
        on: { infinite: _vm.onInfinite },
        scopedSlots: _vm._u([
          {
            key: "spinner",
            fn: function () {
              return [_c("infinity-loading-spinner")]
            },
            proxy: true,
          },
          {
            key: "no-more",
            fn: function () {
              return [_vm._v(" ")]
            },
            proxy: true,
          },
          {
            key: "no-results",
            fn: function () {
              return [
                _c("empty-list-notification", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("newsAndEvent.list.noResults")) +
                      "\n            "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }